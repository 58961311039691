@tailwind base;
@tailwind components;
@tailwind utilities;

@import './assets/fonts/font.css';

body {
  margin: 0;
  padding: 0;
  font-family: Urbanist;
}

.account-profile-bg {
  background: linear-gradient(
    180deg,
    #1b7679 -5.51%,
    #0f2a42 62.63%,
    #020d17 100%
  );
}
:root {
  --swiper-pagination-color: #1eefa4;
  --swiper-pagination-bullet-inactive-color: rgba(204, 252, 255, 0.2);
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1eefa4;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1eefa4;
}

.custom-modal::-webkit-scrollbar {
  display: none;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: none !important;
  -webkit-box-shadow: 0 0 0 30px #14505d inset !important;
  color: white;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #bdced1 inset; /* Change the color to your own background color */
  -webkit-text-fill-color: #bdced1;
}
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px #bdced1 inset;
  -webkit-text-fill-color: #bdced1;
}

.web3modal-modal-card,
.web3modal-modal-lightbox {
  z-index: 200 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@media only screen and (max-width: 768px) {
  .menu-mobile-background {
    background: linear-gradient(180deg, #3d888d 0%, #2e4450 100%);
  }
}
.web3modal-modal-card {
  display: grid !important;
  column-gap: 20px !important;
  grid-template-columns: 1fr 1fr !important;
  background-color: #0e4549 !important;
  padding: 34px !important;
}

.web3modal-provider-wrapper {
  border: none !important;
}

.web3modal-provider-wrapper:first-child .web3modal-provider-container {
  background-color: #f5841f !important;
}

.web3modal-provider-container {
  display: flex !important;
  flex-direction: row !important;
  height: 72px;
  padding: 16px !important;
  background-color: #3b99fc !important;
}

.web3modal-provider-description {
  display: none !important;
}

.web3modal-provider-name {
  margin-top: 0 !important;
  font-family: Urbanist;
  font-weight: 600 !important;
  color: white !important;
}

@media only screen and (max-width: 768px) {
  .web3modal-provider-wrapper {
    padding: 0 !important;
  }
  .web3modal-modal-card {
    grid-template-columns: 1fr !important;
    padding: 24px !important;
    gap: 20px !important;
  }
  .web3modal-provider-icon {
    width: 40px !important;
    height: 40px !important;
  }
  .web3modal-provider-name {
    font-size: 20px !important;
  }
}

.Toastify__toast-container--top-right {
  top: 91px !important;
  right: 20px !important;
}

.Toastify__progress-bar {
  opacity: 0 !important;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container--bottom-center {
    bottom: 40px !important;
    left: 28px !important;
    width: calc(100vw - 56px) !important;
  }
}

.btn {
  text-transform: none;
  transition-property: none;
  border: none;
  animation: none;
  height: auto;
  min-height: fit-content;
  --btn-focus-scale: 1;
  --animation-btn: 0;
  --animation-input: 0;
}
.btn-sm {
  @apply rounded-full;
}
.btn-secondary {
  @apply text-white;
}
.btn-icon {
  @apply bg-transparent;
  @apply p-0;
}
.btn-icon:hover {
  @apply bg-transparent;
  @apply opacity-80;
}
.btn-icon:disabled {
  @apply bg-transparent;
}
.btn-primary:disabled,
.btn-secondary:disabled,
.btn-disabled {
  @apply bg-gray-800;
  @apply text-white;
}
.loading:before {
  display: none;
}

.loading,
.loading:hover {
  pointer-events: none;
}
.loading:after {
  margin-left: 0.5rem;
  height: 1rem;
  width: 1rem;
  border-radius: 9999px;
  border-width: 2px;
  content: '';
  border-top-color: #7e9195;
  border-left-color: #7e9195;
  border-bottom-color: #7e9195;
  border-right-color: currentColor;
  @apply animate-spin;
}

.s-divider {
  @apply bg-fill-3;
}
.s-divider-h {
  height: 1px;
  width: 100%;
}
.s-divider-v {
  width: 1px;
  height: 100%;
}
.s-input {
  @apply bg-fill-2;
  @apply rounded-xl;
  @apply h-9;
  @apply w-full;
  @apply px-4;
  @apply outline-none;
  @apply text-base;
  @apply placeholder-gray-800;
}
.landing-bg {
  background-color: rgb(3, 13, 23);
  background-image: url('./assets/images/bnb_bg.png');
}
.landing-linear-secondary {
  background: linear-gradient(180deg, #1eefa4 19.06%, #19c084 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.landing-linear-big {
  background: linear-gradient(270deg, #1eefa4 -2.16%, #009acb 99.83%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.landing-title {
  font-style: normal;
  font-weight: 700;
  text-align: center;
  background: linear-gradient(180deg, #1eefa4 52.17%, #19c084 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
